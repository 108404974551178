import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import { Grid, Link, Container, Typography, IconButton, Stack } from '@mui/material';

import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import useWordings from 'locales/wordings/useWordings';
const discordFill = 'akar-icons:discord-fill';
const telegramFill = 'bx:bxl-telegram';
const instagramFill = 'bi:instagram';
// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'Twitter', icon: twitterFill, url: 'https://twitter.com/TheVoiceNFT' },
  { name: 'Instagram', icon: instagramFill, url: 'https://www.instagram.com/thevoicecrypto/' },
  { name: 'Telegram', icon: telegramFill, url: 'https://t.me/tvcchat' },
  { name: 'Discord', icon: discordFill, url: 'https://discord.gg/CDjgnxrrRh' },
  {
    name: 'Linkedin',
    icon: linkedinFill,
    url: 'https://www.linkedin.com/company/the-voice-crypto/'
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { comunnityWordings, copyrightWordings } = useWordings();

  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ pt: 15 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" paragraph>
              {comunnityWordings.ourComunnity}
            </Typography>
            <Typography variant="subtitle2" sx={{ pr: { md: 5 } }}>
              {comunnityWordings.ourComunnityDesc1}
              <br />
              {comunnityWordings.ourComunnityDesc2}
            </Typography>

            <Stack spacing={1.5} direction="row" justifyContent="center" sx={{ mt: 4, mb: 1 }}>
              {SOCIALS.map((social) => (
                <Link key={social.name} href={social.url} target="_blank">
                  <IconButton color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={22} height={22} />
                  </IconButton>
                </Link>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 14,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © {new Date().getFullYear()}. {copyrightWordings}
        </Typography>
      </Container>
    </RootStyle>
  );
}
