import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import { MHidden } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
//
// import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import NavDesktop from './NavDesktop';
import MultiLanguageSelector from 'settings/MultiLanguageSelector';
import useWordings from 'locales/wordings/useWordings';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const { menuWordings, stageWordings } = useWordings();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const navConfig = [
    {
      title: menuWordings.home,
      path: 'move_top',
      icon: <Icon icon={homeFill} width={22} height={22} />
    },
    {
      title: menuWordings.NFTs,
      path: 'nfts',
      icon: <Icon icon="bi:images" width={22} height={22} />,
      children: [
        {
          subheader: 'Nfts',
          items: [
            {
              title: 'Singers',
              path: 'singers'
            },
            {
              title: 'Mystery Box',
              path: 'boxes'
            },
            {
              title: stageWordings.stages,
              path: 'stages'
            }
          ]
        }
      ]
    },
    {
      title: menuWordings.gameModes,
      path: 'games',
      icon: <Icon icon="ion:rocket" width={22} height={22} />
    },
    {
      title: menuWordings.roadmap,
      path: 'roadmap',
      icon: <Icon icon="clarity:map-marker-solid" width={22} height={22} />
    },
    {
      title: menuWordings.team,
      path: 'team',
      icon: <Icon icon="eva:people-fill" width={22} height={22} />
    }
  ];

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: '#11111159' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            {/* <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} /> */}
            <NavDesktop navConfig={navConfig} />
          </MHidden>

          <MultiLanguageSelector />

          <Button
            sx={{ color: '#FFFFFF' }}
            variant="contained"
            target="_blank"
            href="https://app.thevoicecrypto.org/"
          >
            {menuWordings.preSale}
          </Button>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
