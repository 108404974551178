import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
// hooks
import useTranslations from 'hooks/useTranslations';

export default function MultiLanguageSelector() {
  const { allLang, onChangeLang, currentLang } = useTranslations();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any, newLang: string) => {
    setAnchorEl(null);
    if (newLang !== 'backdropClick') {
      onChangeLang(newLang);
    }
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        sx={{
          p: 0,
          height: 20,
          width: 28,
          borderRadius: '3px'
        }}
      >
        <Box
          component="img"
          alt={currentLang.label}
          src={currentLang.icon}
          sx={{ p: 0, height: 20, width: 28, borderRadius: '3px' }}
        />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {allLang.map((lang) => (
          <MenuItem onClick={(e) => handleClose(e, lang.value)} key={lang.value}>
            <Box
              component="img"
              alt={lang.label}
              src={lang.icon}
              sx={{ height: 20, width: 28, borderRadius: '3px' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
