// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
// i18n
import './locales/i18n';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <GlobalStyles />
        <ProgressBarStyle />
        <ScrollToTop />
        <Router />
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
