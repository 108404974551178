import { Link } from 'react-scroll';
import { styled } from '@mui/material/styles';
import { Link as LinkMaterial, LinkProps } from '@mui/material';
import { ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import useWordings from 'locales/wordings/useWordings';
import useTranslations from 'hooks/useTranslations';
import { useLocation } from 'react-router';

interface RouterLinkProps extends LinkProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
}

const LinkStyle = styled(LinkMaterial)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none'
  }
}));

type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

interface Props {
  navConfig: MenuItemProps[];
}

const NavDesktop = ({ navConfig }: Props) => {
  const { menuWordings } = useWordings();
  const { currentLang } = useTranslations();
  const { hash } = useLocation();

  return (
    <nav className="nav" id="navbar">
      <div className="nav-content">
        {navConfig.map((link) => (
          <Link
            key={link.title}
            activeClass="active"
            to={link.path}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <LinkStyle
              to={'/#' + link.path}
              component={RouterLink}
              end={link.path === '/'}
              sx={{
                ...(hash === `#${link.path}` && { color: 'primary.main' })
              }}
            >
              {link.title}
            </LinkStyle>
          </Link>
        ))}
        <a
          href={
            currentLang.value === 'es'
              ? 'https://whitepaper.thevoicecrypto.org/the-voice-crypto/introduccion'
              : 'https://whitepaper.thevoicecrypto.org/v/english/the-voice-crypto/introduction'
          }
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none'
          }}
        >
          <LinkStyle>{menuWordings.whitepaper}</LinkStyle>
        </a>
        <a
          href={'/static/TheVoiceCrypto-PitchDeck.pdf'}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none'
          }}
        >
          <LinkStyle>{menuWordings.pitchDeck}</LinkStyle>
        </a>
      </div>
    </nav>
  );
};

export default NavDesktop;
